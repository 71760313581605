import { urlSetter } from "@/counterSlice";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Heading,
  useColorMode,
} from "@chakra-ui/react";
import { useInView } from "framer-motion";
import Link from "next/link";
import { CSSProperties, useRef } from "react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { BiPlay } from "react-icons/bi";
import Includes from "./Includes";
import Thumbnail from "./Thumbnails";

interface ItemProp {
  item: {
    id: number;
    card: {
      id: number;
      uuid: string;
      image: string;
      inc_list: string[];
      title: string;
      ext_file?: string;
      comp_file?: string;
      thumbnail?: string;
      desc?: string;
      price?: number;
      includes?: string;

      song: {
        file?: string;
        title: string;
        ext_file?: string;
      };
    };
  };
}

const FeaturedCard = ({ item }: ItemProp) => {
  const { colorMode } = useColorMode();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const dispatch = useAppDispatch();
  const backgroundColor = colorMode === "light" ? "light.100" : "dark.100";
  const cardPlaying = useAppSelector((state) => state.nowplaying.card);

  return (
    <div key={item.id} ref={ref}>
      <>
        <br />
        <Center
          style={{
            transform: isInView ? "none" : "translateY(+100px)",
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Card
            maxW="xs"
            align="center"
            bgColor={colorMode == "dark" ? "black" : "white"}
            variant="outline"
            borderColor={colorMode == "dark" ? "white" : "black"}
            borderStyle="dotted"
          >
            <CardBody>
              <Link href={`/cards/${item.card.uuid}`}>
                <Thumbnail
                  url={item.card.image}
                  thumbnail={item.card.thumbnail}
                  ext_file={item.card.ext_file}
                  comp_file={item.card.comp_file}
                />
              </Link>
              <br />
              <Box
                border="1px solid #ccc"
                borderRadius="lg"
                p={3}
                onClick={() => dispatch(urlSetter(item.card))}
                style={{ cursor: "pointer" }}
                borderColor={colorMode == "dark" ? "white" : "black"}
                borderStyle="dotted"
              >
                <Heading
                  size="md"
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {item.card.title}
                  {cardPlaying.song != item.card.song && (
                    <div style={{ background: "transparent", border: "none" }}>
                      <BiPlay size={30} />
                    </div>
                  )}
                  {cardPlaying.song == item.card.song && (
                    <AiOutlineEllipsis size={30} />
                  )}
                </Heading>
              </Box>

              <br />
              <Accordion allowMultiple>
                <AccordionItem>
                  <Heading as="h2">
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Included items
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel pb={4}>
                    <Includes includesList={item.card.inc_list} />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <br></br>
              <Center>
                <Link href={`/cards/${item.card.uuid}`}>
                  <Button
                    bg={colorMode === "dark" ? "light" : "white"}
                    size="sm"
                    border="1px"
                    borderColor="gray"
                  >
                    View card
                  </Button>
                </Link>
              </Center>
            </CardBody>
          </Card>
        </Center>
      </>
    </div>
  );
};

const containerStyle: CSSProperties = {
  whiteSpace: "pre-line",
};

export default FeaturedCard;
